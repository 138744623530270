import { css } from 'styled-components'
import { Property } from 'csstype'

export interface Border {
  width: Property.Width<string>
  style: Property.BorderStyle
  color: Property.Color
}
export interface BorderBox {
  radius?: Property.BorderRadius<string>
  left?: Border
  top?: Border
  right?: Border
  bottom?: Border
}

export const border = (entry: BorderBox) => css`
  border-radius: ${entry.radius || 0};
  ${entry.top &&
  `border-top: ${entry.top.width} ${entry.top.style} ${entry.top.color}`};
  ${entry.right &&
  ` border-right:${entry.right.width} ${entry.right.style} ${entry.right.color}`};
  ${entry.left &&
  ` border-left:${entry.left.width} ${entry.left.style} ${entry.left.color}`};
  ${entry.bottom &&
  ` border-bottom:${entry.bottom.width} ${entry.bottom.style} ${entry.bottom.color}`};
`
