import { pushToDataLayer } from '@thg-commerce/enterprise-metrics/src/data_layer'

export const addedToBasketModalDataTrackingCallbacks = {
  modalViewed: () => {
    return pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'viewed',
        eventCategory: 'Product | AddedToBasket',
        eventLabel: 'AddToBasket Modal',
        eventLabelValue: undefined,
      },
    })
  },
  closeClicked: () => {
    return pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'clicked',
        eventCategory: 'Product | AddedToBasket',
        eventLabel: 'close modal',
        eventLabelValue: undefined,
      },
    })
  },
  continueShoppingClicked: () => {
    return pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'clicked',
        eventCategory: 'Product | AddedToBasket',
        eventLabel: 'continue shopping',
        eventLabelValue: undefined,
      },
    })
  },
  viewBasketClicked: () => {
    return pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'clicked',
        eventCategory: 'Product | AddedToBasket',
        eventLabel: 'view basket',
        eventLabelValue: undefined,
      },
    })
  },
}
