import * as React from 'react'

import {
  Box,
  Button,
  ButtonWrapper,
  Picture,
  PicturePreview,
  PreviewTextWrapper,
  PreviewWrapper,
  PriceWrapper,
  RecommendationsWrapper,
  RecommendationWrapper,
} from './styles'

export const AddedToBasketModalSkeleton = () => {
  const recommendations = Array(4)
    .fill(1)
    .map((_, i) => (
      <RecommendationWrapper key={i}>
        <PicturePreview />
        <Box marginTop={2} />
        <Box marginTop={1} width="75%" />
        <Box marginTop={2} />
        <Box marginTop={2} width="50%" />
      </RecommendationWrapper>
    ))

  return (
    <React.Fragment>
      <Box width="50%" height="32px" />
      <PreviewWrapper>
        <Picture />
        <PreviewTextWrapper>
          <Box />
          <Box width="50%" />
          <Box width="66%" />
          <Box width="50%" />
        </PreviewTextWrapper>
      </PreviewWrapper>
      <PriceWrapper>
        <Box width="20%" />
        <Box width="20%" />
      </PriceWrapper>
      <Box width="50%" marginTop={1} />
      <Box height="1px" marginTop={3} />
      <ButtonWrapper>
        <Button />
        <Button />
      </ButtonWrapper>
      <Box width="75%" marginTop={4} />
      <RecommendationsWrapper>{recommendations}</RecommendationsWrapper>
    </React.Fragment>
  )
}
