import { Button } from '@thg-commerce/gravity-elements'
import { styled } from '@thg-commerce/gravity-patterns/theme'
import { Grid, GridItem } from '@thg-commerce/gravity-system'
import { border, margin, mq, spacing, Text } from '@thg-commerce/gravity-theme'

export const ModalTitle = styled.h2`
  ${Text('large1', 'alternate')};
  width: 100%;
  display: block;
  text-align: left;
  ${(props) => margin(props.theme.patterns.addedToBasketModal.title.margin)};
`

export const RecommendationsTitle = styled.h3`
  ${Text('medium1', 'alternate')};
  width: 100%;
  display: block;
  text-align: left;
  margin: ${spacing(4)} 0 ${spacing(3)} 0;
`

export const BasketInformation = styled.div`
    padding-bottom: ${spacing(1)};
    ${(props) =>
      margin(
        props.theme.patterns.addedToBasketModal.content.basketInformation
          .spacing,
      )};
    border-bottom: ${(props) =>
      border(props.theme.patterns.addedToBasketModal.separator)}
    display: flex;
    flex-direction: column;
`

export const SubtotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const SubtotalLabel = styled.span`
  ${Text('medium1', 'default')};
`

export const PriceLabel = styled.span`
  ${Text('medium1', 'alternate')};
`

export const ItemInformation = styled.div`
  padding-bottom: ${spacing(2)};
  ${Text('small', 'default')};
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const AddedToBasketModalButton = styled(Button)`
  width: 100%;
  text-align: center;
  :first-child {
    margin-bottom: ${spacing(2)};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    width: calc(50% - ${spacing(2)});

    :first-child {
      margin-bottom: 0;
    }
  }
`

export const RecommendationsItem = styled(GridItem)`
  margin: 0;
`

export const RecommendationsContainer = styled(Grid)`
  margin: 0;
  grid-template-columns: repeat(2, [col] 1fr);
  grid-gap: ${spacing(2)};

  ${RecommendationsItem}:nth-of-type(n + 3) {
    display: none;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    grid-template-columns: repeat(4, [col] 1fr);

    ${RecommendationsItem}:nth-of-type(n + 3) {
      display: block;
    }
  }
`
