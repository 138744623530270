import { Skeleton } from '@thg-commerce/gravity-elements'
import { mq, spacing, styled } from '@thg-commerce/gravity-theme'

export const Box = styled(Skeleton)<{
  height?: string
  marginTop?: number
  width?: string
}>`
  width: ${(props) => props.width || '100%'};
  margin-top: ${(props) => spacing(props.marginTop || 0)};
  height: ${(props) => props.height || '18px'};
`

export const PreviewWrapper = styled.div`
  display: flex;
  margin-top: ${spacing(3)};
`

export const Picture = styled(Skeleton)`
  height: 93px;
  width: 93px;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    height: 117px;
    width: 117px;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    height: 174px;
    width: 174px;
  }
`

export const PreviewTextWrapper = styled.div`
  flex-grow: 1;
  margin-left: ${spacing(2)};
  & > :not(:first-child) {
    margin-top: ${spacing(1)};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin-left: ${spacing(4)};
  }
`

export const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${spacing(3)};
`

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: ${spacing(3)};
  flex-direction: column;
  & > :not(:first-child) {
    margin-top: ${spacing(2)};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    flex-direction: row;
    & > :not(:first-child) {
      margin-top: 0;
      margin-left: ${spacing(4)};
    }
  }
`

export const Button = styled(Skeleton)`
  height: 48px;
  width: 100%;
`

export const PicturePreview = styled(Skeleton)`
  height: 0;
  padding-bottom: 100%;
  width: 100%;
`

export const RecommendationsWrapper = styled.div`
  display: flex;
  margin-top: ${spacing(3)};
  & > :not(:first-child) {
    margin-left: ${spacing(2)};
  }
  & > :nth-child(n + 3) {
    display: none;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    & > :nth-child(n + 3) {
      display: inline;
    }
  }
`

export const RecommendationWrapper = styled.div`
  width: 50%;
  display: inline;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    width: 25%;
  }
`
